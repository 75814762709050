import React, { useEffect, useState } from "react"
import "../src/App.css";
import Loader from "../src/components/Loader.jsx";
import { fetchCountryFromIP } from './middleware/ipinfoMiddleware.js';
import CGSLOGO from "./assets/logo/cognate-logo.png";
import { Helmet } from "react-helmet-async";
import SalonM from "./assets/img/cognate-slide-1.png";

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [isFrom, setIsfrom] = useState(false);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {

        setIsLoading(true);

        let countryCode = localStorage.getItem('cc');

        if (!countryCode) {
          countryCode = await fetchCountryFromIP();
          localStorage.setItem('cc', countryCode);
        }

        setIsLoading(false);

        if (countryCode !== "NG" && countryCode !== "US" && countryCode !== "GB") {
          return setIsfrom(true);
        }

        let lowerCaseCountryCode;

        if (countryCode === "GB") {
          lowerCaseCountryCode = "uk";
        } else {
          lowerCaseCountryCode = countryCode.toLowerCase();
        }

        window.location.href = `https://cognateglobalsalon.com/${lowerCaseCountryCode}`;

      } catch (error) {
        setIsfrom(false);
      }
    };

    fetchCountryCode();
  }, []);

  if (isLoading) {
    return <div>
      <img
        src={SalonM}
        alt="makeup"
        className="absolute w-screen h-screen object-cover bg-cover bg-center bg-no-repeat opacity-50 rounded-md"
      />
      <div className="mx-auto px-4 py-32 flex h-screen items-center backdrop-brightness-50">
        <div className="mx-auto max-w-5xl text-center">
          <div className=" text-white bg-white border rounded p-5">
            <div className="flex flex-col justify-center items-center">
              <img src={CGSLOGO} alt="cognate global salon logo" className='h-14' />
              <h4 className="mb-6 text-xl text-black font-semibold my-2">Cognate Global Salon</h4>
              <h1 className="text-black font-regular mb-2">Checking, if service is available in your country</h1>
              <Loader />

            </div>
          </div>
        </div>
      </div>

    </div>
  }

  if (isFrom) {
    return <div>
      <img
        src={SalonM}
        alt="makeup"
        className="absolute w-screen h-screen object-cover bg-cover bg-center bg-no-repeat opacity-50 rounded-md"
      />
      <div className="mx-auto px-4 py-32 flex h-screen items-center backdrop-brightness-50">
        <div className="mx-auto max-w-5xl text-center">
          <div className=" text-white bg-white border rounded p-5">
            <div className="flex flex-col justify-center items-center">
              <img src={CGSLOGO} alt="cognate global salon logo" className='h-14' />
              <h4 className="mb-6 text-xl text-black font-semibold my-2">Cognate Global Salon</h4>
              <h1 className="text-black font-regular mb-2">This service isn't available in your country</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div>
      <Helmet>
        <title>Cognate Global Salon: Service Unlimited</title>
        <meta name="description" content="Cognate Global Salon offers premium hair sales and service bookings. Our services are in the United Kingdom, United State of America, & Nigeria." />
        <meta name="keywords" content="contact, cognate global salon nigeria, cognate global salon united state of america, cognate global salon united kingdom, premium hair sales, service booking" />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content="Cognate Global Salon" />
        <meta property="og:description" content="Cognate Global Salon offers premium hair sales and service bookings. Our services are in the United Kingdom, United State of America, & Nigeria." />
        <meta property="og:url" content="https://www.cognateglobalsalon.com" />
        <meta property="og:type" content="website" />
      </Helmet>
    </div>
  );
}

export default App;
