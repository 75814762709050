import { ip_token } from "../constants";

const fetchCountryFromIP = async () => {
    try {
        const response = await fetch(`https://ipinfo.io/json?token=${ip_token}`)
        const data = await response.json();
        return data.country;
    } catch (error) {
        console.error('Error fetching country from IP:', error);
        throw error;
    }
};

const saveCountryCodeToLocalStorage = async () => {
    try {
        const countryCode = await fetchCountryFromIP();
        localStorage.setItem('cc', countryCode);
    } catch (error) {
        console.error('Error saving country code to localStorage:', error);
        throw error;
    }
};

export { fetchCountryFromIP, saveCountryCodeToLocalStorage };